import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="Éxodo" />
      <h1>Tipos y Sombras En El Antiguo Testamento </h1>
      <p>Abajo están las clases transcritas. </p>
      <h2>Tipos y Sombras en Éxodo </h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="29">
              <div align="center">01</div>
            </td>
            <td width="434">El Éxodo 1 - Introducción </td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/045TS_Exodo01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">02</div>
            </td>
            <td>El Éxodo 2 - El Eterno Propósito, La Redención </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/046TS_Exodo02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">03</div>
            </td>
            <td>El Éxodo 3 - El Juicio </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/047TS_Exodo03.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">04</div>
            </td>
            <td>El Éxodo 4 - Las Fiestas de Israel </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/048TS_Exodo04.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">05</div>
            </td>
            <td>El Viaje de Israel 1 - Cuadro de Nuestra Salvación </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/049TS_ViajeDeIsrael01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">06</div>
            </td>
            <td>El Viaje de Israel 2 - Las Tres Partes </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/050TS_ViajeDeIsrael02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">07</div>
            </td>
            <td>El Día de Reposo </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/051TS_DiaDeReposo.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">08</div>
            </td>
            <td>Pentecostés - La Entrada a la Relación </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/052TS_Pentecostes.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">09</div>
            </td>
            <td>Éxodo 23 - La Obra del Espíritu </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/053TS_Exodo_23.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">10</div>
            </td>
            <td>El Tabernáculo 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/054TS_Tabernaculo01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">11</div>
            </td>
            <td>El Tabernáculo 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/055TS_Tabernaculo02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">12</div>
            </td>
            <td>El Becerro de Oro </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/056TS_BecerroDeOro.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">13</div>
            </td>
            <td>La Gloria de Dios </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('tiposysombras/057TS_GloriaDeDios.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
